import apiService from "../ApiService";
import { IParamGroupDto, IMinCategory } from "@/Models/Category";

async function getCategories() {
  return await apiService
    .setUrl(`/categories`)
    .request();
};

async function getChildCategories(id: number) {
  return await apiService
    .setUrl(`/categories?parentId=${id}&lang=uz`)
    .request();
};

async function getCategoryById(id: string | number) {
  return await apiService
    .setUrl(`categories/${id}`)
    .request();
};

async function getCategoryBySlug(slug: string) {
  return await apiService
    .setUrl(`categories/by-slug/${slug}`)
    .request();
};

async function getAllCategories() {
  return await apiService
    .setUrl(`/categories/all-childs`)
    .request<IMinCategory[]>();
};

async function getCategoryParams(categoryId: number) {
  let lng = (typeof localStorage === undefined) ? 'uz' : localStorage.getItem('BROWSER_LANGUAGE');
  lng = lng ?? 'uz';

  return await apiService
    .setUrl(`/params/groups/${categoryId}`)
    .setHeaders({ 'Accept-Language': lng })
    .request<IParamGroupDto[]>();
};

const CategoryApi = {
  getCategories,
  getCategoryById,
  getChildCategories,
  getAllCategories,
  getCategoryParams,
  getCategoryBySlug
}

export default CategoryApi;
