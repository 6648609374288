import { styled } from "@mui/material/styles";

const TitledCardWrapper = styled('div')`
  background: #FFFFFF;
  /* box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06); */
  border-radius: 10px;
  overflow: hidden;

  .card-header {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: ${props => props.theme.customTheme.sizes.normal};
    line-height: 19px;
    padding: 12px 10px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    color: #000;
  }

  .card-content {
    padding: 12px 10px;
  }
`;

export {
  TitledCardWrapper,
};
