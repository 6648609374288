import React from 'react';

type Props = {};

const CategoryBog = (props: Props) => {
  return (
    <svg id='bog' width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5 20.9997V12.9997H7.5V20.9997M12.5 20.9997H16C16.7956 20.9997 17.5587 20.6836 18.1213 20.121C18.6839 19.5584 19 18.7953 19 17.9997V9.33869C19.0002 8.91473 18.9106 8.49554 18.7371 8.10875C18.5635 7.72195 18.3098 7.37634 17.9929 7.09469L11.9929 1.75769C11.4438 1.2696 10.7347 1 10 1C9.2653 1 8.55622 1.2696 8.00708 1.75769L2.00708 7.09469C1.6902 7.37634 1.43652 7.72195 1.26294 8.10875C1.08936 8.49554 0.999762 8.91473 1 9.33869V17.9997C1 18.7953 1.31605 19.5584 1.87866 20.121C2.44127 20.6836 3.20435 20.9997 4 20.9997H7.5M12.5 20.9997H7.5'
        stroke='#0C88C6'
        strokeWidth='2'
      />
    </svg>
  );
};

export default CategoryBog;
