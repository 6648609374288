import React, { FC, SetStateAction, useCallback, useState } from 'react';
import CommonSvg, { svgNames } from '@/Components/atoms/CommonSvg';
import Container from '@/Components/atoms/Container';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { INavLink, linkType, links } from './links';
import { HeaderWrapper } from './style';
import SearchInput from '@/Components/moleculas/SearchInput';
import { QS } from '@/Services/QS';
import { IMinCategory } from '@/Models/Category';
import Image from 'next/image';
import LngSelect from '@/Components/atoms/LngSelect';
import MobileNavbar from './MobileNavbar';
import MyLink from '@/Components/atoms/MyLink';
import BaseButton from '@/Components/atoms/BaseComponents/BaseButton';
import BaselineMenu from '@/Components/atoms/CommonSvg/baselineMenu';
import CatalogMenu from './CatalogMenu';
import CloseSvg from '@/Components/atoms/CommonSvg/close';
import { useUserData } from '@/Hooks/useUserData';
import CustomImage from '@/Components/atoms/Image';
import { AuthContext } from '@/Context/Auth';
import { useContextSelector } from 'use-context-selector';
import { Box, Drawer, Icon, IconButton, Stack, SvgIcon } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import dynamic from 'next/dynamic';
import { useIsMobile } from '@/Hooks/useIsMobile';
import { useMyTranslation } from '@/Hooks/useMyTranslation';
import { useAddressChange } from '@/Components/moleculas/AddressDropdown/useAddressChange';
import CatalogMenuNew from '@/Components/layouts/Header/CatalogMenuNew';
import PlusSvg from '@/Components/atoms/CommonSvg/plusSvg';
import { getImageUrlByUrl } from '@/Services/Image';

const AddressDropdown = dynamic(() => import('@/Components/moleculas/AddressDropdown'), {
  ssr: false,
});

interface IHeaderProps {
  onSearch?: (key: string, address: number) => void;
  onSelectCategory?: (cat: IMinCategory) => void;
  onClickLogo?: () => void;
  setOpenCatalog: React.Dispatch<SetStateAction<boolean>>;
  openCatalog: boolean;
}

const SiteHeader: FC<IHeaderProps> = ({ setOpenCatalog, openCatalog, onSearch, onSelectCategory, onClickLogo }) => {
  const { t, language } = useMyTranslation();
  const isMobile = useIsMobile();
  const userData = useUserData()!;
  const router = useRouter();
  const isLogin = useContextSelector(AuthContext, v => v.state.isLogin);

  const { dropdownTitle, pickRegion, regionValue } = useAddressChange();

  const onSubmit = (key: string, address: number) => {
    if (onSearch) {
      onSearch(key, address);
      return;
    }
    router.push(`/search?${QS.stringify({ name: key })}`);
  };

  const closeCatalog = useCallback(() => {
    setOpenCatalog(false);
  }, []);

  const NAV_LIST = (
    <ul className='site-nav__list'>
      {links.map((link, index) => {
        switch (link.type) {
          // case linkType.create: {
          //   return renderCreateLink(link, index);
          // }
          case linkType.sign: {
            return renderLoginLink(link, index);
          }
          default: {
            return renderLink(link, index);
          }
        }
      })}
    </ul>
  );

  return (
    <>
      {!isMobile && (
        <HeaderWrapper style={{ position: 'relative', zIndex: 101 }}>
          <Box sx={{ backgroundColor: 'rgba(249, 250, 252, 1)' }}>
            <Container>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  {/* <SvgIcon color='secondary'>
                    <LocationOnIcon />
                  </SvgIcon> */}
                  <img src='/static/icons/icon.svg' alt='location' />
                  <AddressDropdown dropdownTitle={dropdownTitle} onPick={pickRegion} activeRegion={regionValue} />
                  <li className='site-nav__item'>
                    <div className='bord' />
                  </li>
                  <li className='site-nav__item'>
                    <MyLink href='tel:+998904065335' className='site-nav__link site-nav'>
                      {t('forBusiness')}
                    </MyLink>
                  </li>
                  <li className='site-nav__item'>
                    <MyLink href='tel:+998998013848' className='site-nav__link'>
                      {t('help')}
                    </MyLink>
                  </li>
                </Stack>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                  <li className='site-nav__item'>
                    <MyLink href='/offers' className='site-nav__link' style={{ padding: 0 }}>
                      {t('my_offers')}
                    </MyLink>
                  </li>
                  <li className='site-nav__item'>
                    <MyLink href='/favorites' className='site-nav__link site-nav__link--like'>
                      <CommonSvg name={svgNames.Heart} />
                    </MyLink>
                  </li>
                  <li className='site-nav__item' style={{ marginRight: '-20px' }}>
                    <LngSelect />
                  </li>
                  <li className='site-nav__item'>
                    <div className='bord' />
                  </li>
                  <nav className='site-nav'>{isMobile ? <div style={{ display: 'flex', alignItems: 'center' }}></div> : NAV_LIST}</nav>
                </Box>
              </Stack>
            </Container>
          </Box>
        </HeaderWrapper>
      )}
      <HeaderWrapper>
        <div className='site-main-header'>
          <Container>
            <div className='header-container'>
              <Link href='/' className='logo-wrapper' onClick={onClickLogo || (() => {})}>
                {/* <Image priority src='/static/icons/logo.svg' width={40} height={40} alt='Joyla' className='logo-img' /> */}
                <img src='/static/icons/logo.svg' alt='Joyla' />
                <span className='logo-text'>
                  JOYLA <span>Mahalliy e'lonlar</span>
                </span>
              </Link>

              <div className='header__search-input'>
                <button
                  onClick={() => {
                    setOpenCatalog(!openCatalog);
                  }}
                  className='katalog-button'
                >
                  {!openCatalog ? <BaselineMenu /> : <CloseSvg />} {t('common:katalog')}
                </button>
                <SearchInput size={'small'} onSubmit={onSubmit} />
                <BaseButton
                  onClick={() => {
                    router.push('/create');
                  }}
                  className='add-button'
                >
                  <PlusSvg /> {t('add')}
                </BaseButton>
              </div>
            </div>
          </Container>
        </div>
        <div className={`catalog-menu ${openCatalog ? 'open' : ''}`}>
          <CatalogMenuNew closeCatalog={closeCatalog} />
          {/* <Drawer  anchor={'left'} open={openCatalog} onClose={()=>setOpenCatalog(false)}>
            <CatalogMenuNew closeCatalog={closeCatalog}/>
          </Drawer> */}
        </div>
      </HeaderWrapper>
      {isMobile && <MobileNavbar />}
    </>
  );

  // ------------------------------------------------------------------------------------------------------------------------
  function renderLink(link: INavLink, index: number): React.ReactNode {
    return (
      <li className='site-nav__item' key={index}>
        <MyLink href={link.path} className='site-nav__link'>
          {t(link.label)}
        </MyLink>
      </li>
    );
  }
  function renderLoginLink(link: INavLink, index: number): React.ReactNode {
    return (
      <li className='site-nav__item' key={index}>
        <MyLink href={link.path} className='site-nav__link site-nav__link--login' >
          {!isLogin ? (
            <CommonSvg name={svgNames.User} />
          ) : (
            userData.avatarPath ?  <CustomImage src={getImageUrlByUrl(userData.avatarPath!)} alt={'profile image'} /> : <CommonSvg name={svgNames.User} />
            
          )}
          {isLogin ? (
            <span className='signIn-span'>
              {userData.firstname} {userData.lastname}
            </span>
          ) : (
            <span className='signIn-span'>{t('auth_sign')}</span>
          )}
        </MyLink>
      </li>
    );
  }
};

export default SiteHeader;
