import { styled } from '@mui/material';

const CatalogMenuWrapper = styled('div')`
  /* background-color: #fff; */
  width: 100%;
  color: #000;
  height: 100%;
  overflow-y: scroll;
  position: relative;

  ::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .catalogCategoryMenu {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    .category-link {
      color: #00040f;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        /* text-decoration: underline; */
      }
    }
  }

  .wrapper {
    display: flex;
    gap: 30px;
    /* padding-top: 40px; */

    .left {
      width: 300px;
      height: 100vh;
      background-color: #fff;
      padding: 30px;

      ul {
        list-style: none;
        padding: 0;
        width: 100%;

        li {
          margin-bottom: 10px;

          .active {
            color: #0c88c6;
            background-color: rgba(12, 136, 198, 0.08);
          }
          button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            background: #fff;
            border: none;
            width: 100%;
            text-align: left;
            color: #000;
            padding: 11px;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;

            &:hover {
              background-color: rgba(12, 136, 198, 0.08);
              color: #000;
            }
          }

          .active {
            color: #fff;
            background-color: #0c88c6;

            

            #electronic, #transport, #moda, #mulk, #bola, #bog {
              path {
                fill: #fff;
                /* stroke: #fff; */
                /* stroke-width: 0.1px; */
              }
            }

            #mebel, #fitnes, #service, #hayvon{
              path {
                stroke: #fff;
              }
            }
            &:hover {
              background-color: rgba(12, 136, 198);
              color: #fff;
            }
          }
        }
      }
    }

    .right {
      width: 75%;
      .wrap {
        display: flex;
        flex-wrap: wrap;

        .title {
          color: #000;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration: none;
          cursor: pointer;
        }

        .card {
          width: 30%;

          h3 {
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 12px;
          }

          ul {
            list-style: none;
            padding: 0;

            .see_more {
              color: #0c88c6;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              border: none;
              background: transparent;
              cursor: pointer;
              padding: 0;
            }
            li {
              cursor: pointer;
              margin-bottom: 12px;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.6);
              font-weight: 400;

              .category-link {
                color: rgba(0, 0, 0, 0.6);
                text-decoration: none;

                &:hover {
                  color: #0c88c6;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  .childs {
    padding: 30px;
    position: absolute;
    left: 300px;
    top: 0;
    background-color: red;
    width: 300px !important;
    height: 100vh;
    transition: width 1s;
    background-color: #fff;
    border-left: 1px solid #c3c6cf;

    ul {
      list-style: none;
      padding: 0;
      width: 100%;

      li {
        margin-bottom: 10px;

        .active {
          color: #0c88c6;
          background-color: rgba(12, 136, 198, 0.08);
        }
        button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 12px;
          background: #fff;
          border: none;
          width: 100%;
          text-align: left;
          color: #000;
          padding: 11px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            background-color: rgba(12, 136, 198, 0.08);
            color: #000;
          }
        }

        .active {
          color: #fff;
          background-color: #0c88c6;

          svg {
            path {
              fill: #fff;
            }
          }
          &:hover {
            background-color: rgba(12, 136, 198);
            color: #fff;
          }
        }
      }
    }
  }
  .childs2 {
    padding: 30px;
    position: absolute;
    left: 600px;
    top: 0;
    background-color: red;
    width: 300px !important;
    height: 100vh;
    transition: width 1s;
    background-color: #fff;
    border-left: 1px solid #c3c6cf;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 3px;
      border-radius: 5px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    ul {
      list-style: none;
      padding: 0;
      width: 100%;

      li {
        margin-bottom: 10px;

        .active {
          color: #0c88c6;
          background-color: rgba(12, 136, 198, 0.08);
        }
        button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 12px;
          background: #fff;
          border: none;
          width: 100%;
          text-align: left;
          color: #000;
          padding: 11px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            background-color: rgba(12, 136, 198, 0.08);
            color: #000;
          }
        }

        .active {
          color: #fff;
          background-color: #0c88c6;

          svg {
            path {
              fill: #fff;
            }
          }
          &:hover {
            background-color: rgba(12, 136, 198);
            color: #fff;
          }
        }
      }
    }
  }
`;

export default CatalogMenuWrapper;
