import { styled } from '@mui/material/styles';

export interface IBaseInputAttr extends React.InputHTMLAttributes<HTMLInputElement> {
  [key: string]: any
};


const StyledBaseInput = styled('input')<IBaseInputAttr>`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid ${props => props.theme.customTheme.colors.primary};
  box-shadow: 0 0 1px 1px #fff, 0 0 1px 2px transparent;
  outline: none;
  transition: box-shadow 0.1s ease;
  border: 1px solid ${props => props.theme.customTheme.colors.secondary};
  font-family: 'Inter', 'Arial', sans-serif;

  &:focus {
    box-shadow: 0 0 1px 1px ${props => props.theme.customTheme.colors.primary}, 0 0 1px 2px rgba(255, 255, 255, 0.9);
  }

  &::placeholder{
    font-weight: 500 !important;
  }
`;

export {
  StyledBaseInput,
};
