import React, { FC } from 'react';
import { StyledDiv } from './style';
import SiteHeader from '../Header';
import SiteFooter from '../Footer';
import CookieConsentBanner from '../CookieConsentBanner';
import { ICategory, IMinCategory } from '@/Models/Category';
import { regionValueType } from '@/Components/moleculas/AddressDropdown/Dropdown';

interface IProps {
  children: React.ReactNode;
  onSearch?: (key: string, address: number) => void;
  onSelectCategory?: (cat: IMinCategory) => void;
  onClickLogo?: () => void;
}

const PublicLayout: FC<IProps> = ({ children, onSearch, onSelectCategory, onClickLogo }) => {
  const [openCatalog, setOpenCatalog] = React.useState(false);

  return (
    <StyledDiv>
      {openCatalog ? <div className='mask' onClick={()=>setOpenCatalog(false)}></div> : <></>}
      <SiteHeader
        setOpenCatalog={setOpenCatalog}
        openCatalog={openCatalog}
        onSearch={onSearch}
        onSelectCategory={onSelectCategory}
        onClickLogo={onClickLogo}
      />
      {children}

      <SiteFooter />
      {/* <CookieConsentBanner/> */}
    </StyledDiv>
  );
};

export default PublicLayout;
