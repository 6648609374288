import { IKeyValue } from '@/Types/CommonTypes';
import qs, { IStringifyOptions, IParseOptions } from 'qs';

export const QS = {
  stringify(arg: IKeyValue, options?: IStringifyOptions) {
    const cleanedArg: IKeyValue = {};
    Object.entries(arg).forEach(([key, value], index) => {
      if (value === '' || value == null) return;

      cleanedArg[key] = value;
    });
    return qs.stringify(cleanedArg, options);
  },
  parse(str: string, options?: IParseOptions) {
    return qs.parse(str, options);
  },
};
