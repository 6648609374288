import { styled } from '@mui/material/styles';

const SkeletonDiv = styled('div')`
  position: relative;
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;

    background-color: #ededed;
    background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) #ededed;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }

  &.loading::after {
    content: '';
  }
`;

export { SkeletonDiv };
