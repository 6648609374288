import { useTranslation } from 'next-i18next';

export function useMyTranslation() {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  return {
    language: currentLanguage as 'uz' | 'ru',
    t,
  };
}
