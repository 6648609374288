export const PRODUCT_PAGE_SIZE = 50;
export const WEBSITE_URL = process.env.NEXT_PUBLIC_SITE_URL;
export const CREATE_IMAGE_QUANTITY = 7;
// export const PLATFORM_NAME = 'IOS' || 'WEB';
export const PLATFORM_NAME = 'WEB';
export const VoidFunction = () => undefined;
export type AVAILABLE_PAGE_SIZE_TYPE = 10 | 20 | 30 | 40 | 50 | 60;

export const PAGE_SIZES = [10, 20, 30, 40, 50, 60];

export const CDN_BASE_URL = process.env.NEXT_PUBLIC_CDN_BASE_URL;
