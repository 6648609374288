import React, { FC } from 'react';
import Container from '@/Components/atoms/Container';
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { LiaTelegramPlane } from 'react-icons/lia';
import { StyledSiteFooter, StyledSiteFooterBottom } from './style';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import MyLink from '@/Components/atoms/MyLink';

interface ISiteFooterProps {}

const SiteFooter: FC<ISiteFooterProps> = props => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <>
      <StyledSiteFooter className='site-footer'>
        <Container className='site-footer__container'>
          <ul className='site-footer__row'>
            <li className='site-footer__col top'>
              <Link href={'/'} className='site-footer__logo'>
                <Image priority src='/static/icons/logo.svg' width={40} height={40} alt='Joyla' />
                <span>JOYLA</span>
              </Link>

              <div className='site-footer__first-col-item'>
                <p>{t('footer.footer_text')}</p>
              </div>
              <div className='site-footer__download'>
                <a
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                  className='site-footer__download-link'
                  href='https://play.google.com/store/apps/details?id=uz.joyla.store&hl=ru'
                >
                  <img src='/static/images/android-download.svg' alt='download android app' />
                </a>
                <a
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                  className='site-footer__download-link'
                  href='https://apps.apple.com/uz/app/joyla-mahalliy-elonlar/id1481609705'
                >
                  <img src='/static/images/iphone-download.svg' alt='download iphone app' />
                </a>
              </div>
            </li>
            <li className='site-footer__col'>
              <h4 className='site-footer__col-title'>{t('footer.customer_care')}</h4>
              <ul className='site-footer__col-list'>
                <li>
                  <MyLink href='/profile/wallet'>{t('footer.my_account')}</MyLink>
                </li>
                <li>
                  <MyLink href='/profile/my_posts'>{t('footer.my_posts')}</MyLink>
                </li>
                <li>
                  <MyLink href='/favorites'>{t('footer.saved_posts')}</MyLink>
                </li>
              </ul>
            </li>
            <li className='site-footer__col'>
              <h4 className='site-footer__col-title'>{t('footer.pages')}</h4>
              <ul className='site-footer__col-list'>
                <li>
                  <MyLink href={'/'}>{t('footer.home')}</MyLink>
                </li>
                <li>
                  <MyLink href={'/faq'}>{t('footer.faq')}</MyLink>
                </li>
                <li>
                  <MyLink href={'/terms-and-conditions'}>{t('footer.terms')}</MyLink>
                </li>
                <li>
                  <MyLink href={'/privacy-policy'}>{t('footer.privacy_policy')}</MyLink>
                </li>
              </ul>
            </li>
            <li className='site-footer__col'>
              <div className='site-footer__contact'>
                <h4 className='site-footer__col-title'>{t('footer.contact_info')}</h4>
                <div className='site-footer__contact-phones'>
                  <div>
                    <p>{t('footer.phone1')}</p>
                    <a href='tel:+998(90)4065335'>+998 90 406-53-35</a>
                  </div>
                  <div>
                    <p>{t('footer.phone2')}</p>
                    <a href='tel:+998(99)8013848'>+998 99 801-38-48</a>
                  </div>
                  <div>
                      <p>{t('footer.phone3')}</p>
                      <a href="https://t.me/joyla_operator">@joyla_operator</a>
                    </div>
                    <div>
                    <a href='tel:+998(33)7534073'>+998 33 753-40-73</a>
                    </div>
                </div>
                <a href='mailto:support@joyla.uz'>support@joyla.uz</a>
              </div>
              
            </li>
          </ul>
        </Container>
      </StyledSiteFooter>
      <StyledSiteFooterBottom>
        <Container>
          <div className='inner'>
            <span>{t('footer.all_rights_reserved')}</span>
            <div className='site-footer__first-col-item'>
              <ul className='site-footer__social-list'>
                <li className='site-footer__social-item'>
                  <a href='https://www.facebook.com/joylauzb' target='_blank' rel='noopener noreferrer'>
                    <FiFacebook />
                  </a>
                </li>
                <li className='site-footer__social-item'>
                  <a href='https://www.instagram.com/joylauzb' target='_blank' rel='noopener noreferrer'>
                    <FiInstagram />
                  </a>
                </li>
                <li className='site-footer__social-item'>
                  <a href='https://t.me/joylauzb' target='_blank' rel='noopener noreferrer'>
                    <LiaTelegramPlane />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </StyledSiteFooterBottom>
    </>
  );
};

export default SiteFooter;
