import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import BaseInput from '@/Components/atoms/BaseComponents/BaseInput';
import { useDebouncedCallback } from 'use-debounce';

import ReactSelect from '@/Components/atoms/ReactSelect';
import ProductApi from '@/Services/Axios/Controllers/ProductApi';
import RegionApi from '@/Services/Axios/Controllers/RegionApi';
import { IRegion } from '@/Models/Region/IRegion';
import BaseButton from '@/Components/atoms/BaseComponents/BaseButton';
import { colors } from '@/styles/colors';
import SuggestionList from './SuggestionList';
import { notifyError } from '@/Services/Notification';
import { components } from 'react-select';
import { AddressSelectStyles, StyledSearchForm } from './style';
import { IDistrict } from '@/Models/Region/IRegion';
import { FaArrowLeft } from 'react-icons/fa';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import CommonSvg, { svgNames } from '@/Components/atoms/CommonSvg';

interface ISearchInputProps {
  onSubmit: (key: string, address: number) => void;
  size?: 'normal' | 'small';
}
interface IAddress extends Partial<IRegion>, Partial<IDistrict> {}

const SearchInput: FC<ISearchInputProps> = ({ onSubmit, size = 'normal' }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [address, setAddress] = useState<{ label: React.ReactNode; value: IAddress | null } | null>({ label: 'Butun Ozbekiston', value: null });

  const getSuggestiosList = useCallback(async (searchKey: string) => {
    const response = await ProductApi.getProductSuggestions(searchKey);
    if (response.status >= 200 && response.status < 300) {
      setSuggestions([searchKey, ...(response.data as string[])]);
    }
  }, []);

  const onChange = useDebouncedCallback((e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const inputEl = e.target as HTMLInputElement;
    if (inputEl.value.trim().length < 2) {
      setSuggestions([]);
      return;
    }
    getSuggestiosList(inputEl.value.trim());
  }, 300);

  const handleSearch = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const value = inputRef.current!.value.trim();
    onSubmit(value, address?.value?.id || 2); // tashkentId == 2
  };

  const onSelectFromList = (value: string) => {
    value = value.trim();
    onSubmit(value, address?.value?.id || 2); // tashkentId == 2
  };

  // NOTE - --------------------------- SIDE EFFECTS -------------------------
  // TODO: frontda useLayoutEffect ishlatish kerak
  useEffect(() => {
    if (router.query.name && inputRef.current) {
      inputRef.current.value = router.query.name as string;
    }
  }, []);

  // NOTE - --------------------------- MINI COMPONENTS -------------------------
  const backToAddressList = (
    <BaseButton
      style={{ width: '100%', backgroundColor: colors.primary, padding: '7px 10px' }}
      onClick={() => {
        setAddress({ label: 'Butun Ozbekiston', value: null });
      }}
    >
      <FaArrowLeft /> Viloyatlar
    </BaseButton>
  );

  return (
    <StyledSearchForm onSubmit={handleSearch} size={size}>
      <div className='search-input-list'>
        <BaseInput className='search-input' placeholder={t('search')} ref={inputRef} onChange={onChange} />

        {!!suggestions.length && <SuggestionList getSuggestiosList={getSuggestiosList} list={suggestions} onSelect={onSelectFromList} inputRef={inputRef} />}
      </div>

      <BaseButton className='search-btn' htmlType={'submit'} >
        <CommonSvg name={svgNames.Search} />
      </BaseButton>

      {/* <div className='search-regions-wrapper'> */}
      {/* <div className='search-regions'>
            <ReactSelect
              styles={AddressSelectStyles}
              value={address}
              onChange={setAddress}
              options={regionOptions}
              // @ts-ignore
              components={{ MenuList: (props) => {
                return <components.MenuList  {...props}>
                {
                  !!address?.value && backToAddressList
                }
                {props.children}
              </components.MenuList>
              } }}
            />
          </div> */}
      {/* </div> */}
    </StyledSearchForm>
  );
};

export default SearchInput;
