import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')`
  .mask {
    background: rgba(0, 0, 0, 0.45);
    position: fixed;
    inset: 0;
    z-index: 100;
    height: 100%;
    /* pointer-events: none; */
  }

  @media (max-width: 991.98px) {
    padding-bottom: 55px;
  }

  @media (max-width: 767.98px) {
    font-size: 15px;
  }
`;

export { StyledDiv };
