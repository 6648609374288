import React, { FC } from 'react';
import { StyledContainer } from './style';

interface IContainerProps {
  maxWidth?: string;
  children?: React.ReactNode;
  className?: string;
  [key: string]: any;
}

const Container: FC<IContainerProps> = ({ children, className = '', maxWidth, ...props }) => {
  const def_width = maxWidth ?? '1360px';

  return (
    <StyledContainer className={className} maxWidth={def_width} {...props}>
      {children}
    </StyledContainer>
  );
};

export default Container;
