import React, { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { privateLinks } from '../../../Data/privateLinks';
import { useContextSelector } from 'use-context-selector';
import { AuthContext } from '@/Context/Auth';

interface IMyLink extends React.AnchorHTMLAttributes<HTMLAnchorElement>{
  children?: React.ReactNode;
  href: string;
  className?: string;
  [key: string]: any;
};

const MyLink: FC<IMyLink> = ({href, className = '', ...props}) => {
  const router = useRouter();
  const isLogin = useContextSelector(AuthContext, v => v.state.isLogin);
  const showAuthModal = useContextSelector(AuthContext, v => v.actions.showModal);

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if(privateLinks.includes(href) && !isLogin) {
      event.preventDefault();
      showAuthModal(href);
    };
  };

  return (
    <Link
      href={href}
      className={className + ` ${router.pathname === href ? 'active-navlink' : ''}`}
      {...props}
      onClick={onClick}
      // prefetch={false}
    >
      {props.children}
    </Link>
  )
}

export default MyLink;
