import React from 'react';

type Props = {};

const BaselineMenu = (props: Props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path d='M3.5 18H21.5V16H3.5V18ZM3.5 13H21.5V11H3.5V13ZM3.5 6V8H21.5V6H3.5Z' fill='white' />
    </svg>
  );
};

export default BaselineMenu;
