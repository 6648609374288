import { DetailedHTMLProps, HTMLAttributes, ImgHTMLAttributes, ReactEventHandler, memo, useEffect, useRef, useState } from 'react';
import { SkeletonDiv } from './style';
import omit from 'lodash.omit';

interface ICustomImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  quality?: number;
  priority?: boolean;
  width?: number;
  height?: number;
  alt?: string;
  wrapperClassName?: string;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
}

const CustomImage = (props: ICustomImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const refImage = useRef<HTMLImageElement>(null!);
  const wrapperClassName = props.wrapperClassName || '';

  const onLoad: ReactEventHandler<HTMLImageElement> = event => {
    setIsLoaded(true);
  };

  const onError: ReactEventHandler<HTMLImageElement> = event => {
    event.currentTarget.src = '/static/images/not-image.png'; // '/static/icons/logo.svg';
    setIsLoaded(true);
  };

  useEffect(() => {
    if (refImage.current.complete) {
      setIsLoaded(true);
    }
  }, []);

  return (
    <SkeletonDiv className={isLoaded ? wrapperClassName : `${wrapperClassName} loading`}>
      {
        // eslint-disable-next-line @next/next/no-img-element
        <img
          // loading='lazy'
          ref={refImage}
          onLoad={onLoad}
          onError={onError}
          alt={props.alt || 'image'}
          // {...(props.priority ? { priority: 'true' } : {})}
          {...omit(props, ['quality', 'alt', 'priority'])}
          {...props.wrapperProps}
        />
      }
    </SkeletonDiv>
  );
};

export default memo(CustomImage);
