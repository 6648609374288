import { regionValueType } from '@/Components/moleculas/AddressDropdown/Dropdown';

class AdressService {
  public getAddressValue(slug: string) {
    const type = slug?.slice(-1) as 'r' | 'd';
    const id = this.getIdByString(slug?.slice(0, slug.length - 1));

    return {
      id,
      type: type === 'r' ? 'region' : 'district',
    } as regionValueType;
  }

  private getIdByString(url: string): number {
    const strs = url?.split('-');
    return +strs?.[strs?.length - 1];
  }
}

export const addressService = new AdressService();
