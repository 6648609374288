import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)`
  padding: 6px 8px 6px 6px;
  border-radius: 15px;
  /* border: 1px solid #fff; */
  height: 44px;
  width: 54px;
  /* outline: none; */
  /* transition: border-color 0.3s ease; */
  font-size: ${props => props.theme.customTheme.sizes.small};
  fieldset {
    border: 1px solid transparent !important;
  }


  .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 0 !important;
    height: 100%;
    font-weight: 400;
    text-transform: uppercase;

    .short {
      display: flex;
    }
    .full {
      display: none;
    }
  }
 
  svg {
    padding: 4px;
  }
  .MuiMenu-paper{
    transform: translateY(0) !important;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  .short {
    display: none;
  }
  .language-item-img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
`;

export { StyledSelect, StyledMenuItem };
