import { regionValueType } from '@/Components/moleculas/AddressDropdown/Dropdown';
import { GlobalSiteSettingsContext } from '@/Context/GlobalSiteSettings';
import { addressService } from '@/Models/Region/region.service';
import { getNameKeyByLang, getSlugNameByLang } from '@/Utils/getNameKeyByLang';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useContextSelector } from 'use-context-selector';
import { pageLinks } from '@/Data/pageLinks';
import { isEqualPrimitive } from '@/Utils/isEqualPrimitive';
import { useIsMobile } from '@/Hooks/useIsMobile';


export function useAddressChange() {
  const { t, i18n } = useTranslation();
  const language = i18n.language as 'uz' | 'ru';
  const router = useRouter();
  const isMobile = useIsMobile()
  const regionOptions = useContextSelector(GlobalSiteSettingsContext, v => v.state.regionOptions);

  const [regionValue, setRegionValue] = useState<regionValueType | null>(() => {
    if (router.query.regionSlug) {
      return addressService.getAddressValue(router.query.regionSlug as string);
    } else {
      return null;
    }
  });

  const getActiveRegionName = () => {
    let value = {
      regionName: '',
      districtName: '',
    };

    if (!regionValue) {
      return value;
    }

    if (regionValue.type === 'region')
      regionOptions.forEach(reg => {
        if (String(reg.id) === String(regionValue.id)) {
          value = {
            regionName: reg[getNameKeyByLang(language)],
            districtName: '',
          };
        }
      });

    if (regionValue.type === 'district') {
      regionOptions.forEach(reg => {
        reg.districts.forEach(dis => {
          if (String(dis.id) === String(regionValue.id)) {
            value = {
              regionName: dis?.regionName[getNameKeyByLang(language)],
              districtName: dis?.[getNameKeyByLang(language)],
            };
          }
        });
      });
    }

    return value;
  };

  const pickRegion = useCallback(
    (value: regionValueType) => {
      setRegionValue(value);

      let findedValue = null;
      if (value?.type === 'region') {
        const data = regionOptions.find(i => isEqualPrimitive(i.id, value.id));
        if (data) {
          findedValue = data;
        }
      } else if (value?.type === 'district') {
        regionOptions.forEach(region => {
          const data = region.districts.find(i => isEqualPrimitive(i.id, value.id));
          if (data) {
            findedValue = data;
          }
        });
      }

      if (router.query.categorySlug && findedValue) {
        router.push(
          pageLinks.categoryAddressPage({
            addressSlug: findedValue[getSlugNameByLang(language)],
            categorySlug: router.query.categorySlug as string,
          })
        );
      } else if (findedValue) {
        router.push(pageLinks.categoryAddressPage({ addressSlug: findedValue[getSlugNameByLang(language)] }));
      } else if (router.query.categorySlug && router.query.categorySlug !== 'all') {
        router.push(pageLinks.categoryAddressPage({ categorySlug: router.query.categorySlug as string }));
      } else {
        router.push('/search');
      }
    },
    [regionOptions, router.query]
  );

  const selectedRegionValue = getActiveRegionName();
  const dropdownTitle =
    (regionValue
      ? selectedRegionValue.regionName + `${selectedRegionValue.districtName ? ', ' + selectedRegionValue.districtName : ''}`
      : language === 'uz'
      ? "O'zbekiston bo'ylab"
      : 'По всему Узбекистану');

  return {
    dropdownTitle,
    selectedRegionValue,
    regionValue,
    pickRegion,
  };
}
