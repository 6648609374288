import { colors } from '@/styles/colors';
import { styled } from '@mui/material/styles';

const StyledMobileNavbar = styled('nav')`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;

  height: 60px;
  background-color: #fff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px 12px 0 0;

  .mobile-navbar {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }

    &__link {
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: center;
      text-decoration: none;
      color: ${colors.silver};

      svg {
        width: 25px;
        height: 25px;
      }

      div {
        font-size: ${props => props.theme.customTheme.sizes.small};
      }
    }

    &__svg-wrapper {
      padding: 0.4rem 0.9rem;
      margin-bottom: -1.2rem;
      transform: translateY(-45%);

      background-image: url('/static/icons/Rectangle.svg');
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 15px;

      svg {
        color: #fff;
      }
    }

    &__link--create {
      position: relative;

      &:after {
        position: absolute;
        top: -17px;
        transform: translateY(-50%);
        z-index: -2;

        width: 115px;
        height: 20px;

        background-image: url('/static/icons/ellipse.svg');
        background-size: contain;
        background-repeat: no-repeat;
        filter: drop-shadow(0px -5px 10px rgba(0, 0, 0, 0.1));

        content: '';
      }

      svg {
        width: 25px;
        height: 25px;
      }

      div {
        font-size: ${props => props.theme.customTheme.sizes.small};
      }
    }
  }
 

  .cat {
    svg {
      width: 25px;
      height: 25px;

      path {
        fill: #9da0a9 !important;
      }
    }
  }
  .active-navlink {
    color: ${props => props.theme.customTheme.colors.primary};
    svg {
      path {
        stroke: ${props => props.theme.customTheme.colors.primary} !important;
      }
    }
  }
  .active-navlink.cat {
    svg {
      path {
        fill: ${props => props.theme.customTheme.colors.primary} !important;
        stroke: none !important;
      }
    }
  }

  @media (max-width: 400px) {
    .mobile-navbar {
      &__link {
        div {
          font-size: ${props => props.theme.customTheme.sizes.xSmall};
        }
      }
    }
  }
`;

export { StyledMobileNavbar };
