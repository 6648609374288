import { styled } from '@mui/material/styles';

type StyledContainerProps = {
  maxWidth: string;
  [key: string]: any;
};

const StyledContainer = styled('div')<StyledContainerProps>`
  max-width: ${props => props.maxWidth};
  width: 100%;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: 767.98px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export { StyledContainer };
