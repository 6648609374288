import React, { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface INavlink extends React.AnchorHTMLAttributes<HTMLAnchorElement>{
  children?: React.ReactNode;
  href: string;
  className?: string;
  [key: string]: any;
};

const Navlink: FC<INavlink> = ({href, className = '', ...props}) => {
  const router = useRouter();

  return (
    <Link href={href} className={className + ` ${router.pathname === href ? 'active-navlink' : ''}`} {...props}>
      {props.children}
    </Link>
  )
}

export default Navlink;
