import React, { FC, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { StyledMenuItem, StyledSelect } from './style';
import { Box, SelectChangeEvent, Stack, SvgIcon } from '@mui/material';
import ArrowBottomSvg from '../CommonSvg/arrowBottomSvg';
import { firebase_instance } from '@/Services/Firebase';
import Globe from '../CommonSvg/globe';
import ExpandMore from '@mui/icons-material/ExpandMore';

type ILngSelectProps = {};

const LngSelect: FC<ILngSelectProps> = () => {
  // @ts-ignore
  const { i18n } = useTranslation();
  const router = useRouter();
  const linkRefUz = useRef<HTMLAnchorElement>(null);
  const linkRefRu = useRef<HTMLAnchorElement>(null);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    if (event.target.value === 'uz') {
      linkRefUz.current?.click();
      localStorage.setItem('BROWSER_LANGUAGE', 'uz');
      firebase_instance.updateLanguageOnFcm('uz');
    } else {
      linkRefRu.current?.click();
      localStorage.setItem('BROWSER_LANGUAGE', 'ru');
      firebase_instance.updateLanguageOnFcm('ru');
    }
  };

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Link
        style={{ display: 'none' }}
        ref={linkRefUz}
        href={{ pathname: router.pathname, query: router.query }}
        className={i18n.language === 'uz' ? 'active' : ''}
        locale={'uz'}
      >
        <img src='/static/icons/uzbekistan.png' alt='' />
        UZ
      </Link>
      <Link
        style={{ display: 'none' }}
        ref={linkRefRu}
        href={{ pathname: router.pathname, query: router.query }}
        className={i18n.language === 'ru' ? 'active' : ''}
        locale={'ru'}
      >
        RU
      </Link>
      {i18n.language === 'uz' ? (
        <img style={{ width: '20px' }} src='/static/icons/uzbekistan.png' alt='' />
      ) : (
        <img style={{ width: '20px' }} src='/static/icons/russia.png' alt='' />
      )}

      <StyledSelect
        value={i18n.language}
        onChange={handleChange}
        IconComponent={props => (
          <SvgIcon {...props}>
            <ExpandMore />
          </SvgIcon>
        )}
      >
        <StyledMenuItem disableTouchRipple value={'uz'} >
          <span className='full'>O&apos;zbek</span>
          <span className='short'>UZ</span>
        </StyledMenuItem>
        <StyledMenuItem disableTouchRipple value={'ru'}>
          <span className='full'>Русский</span>
          <span className='short'>Ру</span>
        </StyledMenuItem>
      </StyledSelect>
    </Stack>
  );
};

export default LngSelect;
