import React, { FC, RefObject, useCallback } from 'react'
import { useTranslation } from 'next-i18next';

interface ISuggestionProps {
  list: string[];
  getSuggestiosList: (key: string) => void;
  onSelect: (value: string) => void;
  inputRef: React.RefObject<HTMLInputElement>
};

const SuggestionList: FC<ISuggestionProps> = ({ list, onSelect, getSuggestiosList, inputRef }) => {
  const { t } = useTranslation();

  const handleSuggestion = useCallback((e: React.MouseEvent<HTMLInputElement>, value: string) => {
    onSelect(value);
    getSuggestiosList(value);
    (e.target as HTMLInputElement).blur();
  }, []);

  const handleKeydownSuggestion = useCallback((e: React.KeyboardEvent<HTMLInputElement>, value: string) => {
    e.preventDefault();
    if(e.code === 'Enter' || e.code === 'Space' || e.code === ' ') {
      onSelect(value);
      (e.target as HTMLInputElement).blur();
      getSuggestiosList(value);
    };
    if(e.code === 'ArrowDown') {
      const element = (e.target as HTMLInputElement).nextElementSibling;
      element && (element as HTMLInputElement).focus();
    }
    if(e.code === 'ArrowUp') {
      const element = (e.target as HTMLInputElement).previousElementSibling;
      element && (element as HTMLInputElement).focus();
    }
  }, []);

  return (
    <div className='suggestions'>
      <ul className='suggestions__list'>
        {
          list.map((value: string, index: number) => {
            const _value = index === 0 ? t('show_all_results', { key: value }) : value
            return (
              <input
                tabIndex={0}
                className='suggestions__item'
                key={index}
                value={_value}
                spellCheck={false}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => handleSuggestion(e, value)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeydownSuggestion(e, value)}
              />
          )})
        }
      </ul>
    </div>
  )
}

export default SuggestionList;