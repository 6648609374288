import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import { StyledBaseInput, IBaseInputAttr } from './style';

export interface IBaseInputProps extends IBaseInputAttr {
  children?: React.ReactNode;
};


const BaseInput: ForwardRefRenderFunction<HTMLInputElement, IBaseInputProps> = ({ children, ...props }, ref) => {

  return (
    <StyledBaseInput {...props} ref={ref} />
  )
}

export default forwardRef(BaseInput);