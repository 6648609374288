import React from 'react';

type Props = {};

const CategoryHayvon = (props: Props) => {
  return (
    <svg id='hayvon' width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.8698 9.81442C20.5142 11.0745 19.2825 12.3537 17.8758 11.9546C16.4515 11.5456 16.1033 9.80768 16.4577 8.5514C16.8155 7.29593 18.0264 6.00406 19.4518 6.41121C20.8761 6.82018 21.2279 8.55727 20.8698 9.81442ZM7.82839 8.03247C9.59348 8.03247 10.4363 6.01363 10.4363 4.51655C10.4363 3.01926 9.59396 1 7.82839 1C6.06101 1 5.22051 3.01752 5.22051 4.51655C5.22051 6.01535 6.06151 8.03247 7.82839 8.03247ZM14.1716 8.03247C15.9385 8.03247 16.7795 6.01535 16.7795 4.51655C16.7795 3.01753 15.939 1 14.1716 1C12.4061 1 11.5638 3.01924 11.5638 4.51655C11.5638 6.01363 12.4065 8.03247 14.1716 8.03247ZM5.54233 8.55185C5.18473 7.2962 3.97383 6.00407 2.54821 6.41125C1.12354 6.81907 0.772179 8.55782 1.13014 9.81446C1.48418 11.0689 2.6996 12.363 4.12227 11.9546C5.54762 11.5466 5.89725 9.80903 5.54233 8.55185ZM11 9.52272C7.78217 9.52272 5.65738 12.5778 5.17366 15.4735C4.83668 17.4979 6.64505 19.5947 8.75685 18.8408C9.47272 18.5844 9.99909 18 11 18C12.0009 18 12.5273 18.5844 13.2432 18.8408C15.3539 19.5941 17.1632 17.4973 16.8263 15.4735C16.3426 12.5772 14.2187 9.52272 11 9.52272Z'
        stroke='#0C88C6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CategoryHayvon;
