import React from 'react';

type Props = {};

const CategoryMebel = (props: Props) => {
  return (
    <svg id="mebel" width='24' height='17' viewBox='0 0 24 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 9V3C19 1.89543 18.1046 1 17 1H7C5.89543 1 5 1.89543 5 3V9M19 9H5M19 9V8C19 6.89543 19.8954 6 21 6C22.1046 6 23 6.89543 23 8V12C23 13.1046 22.1046 14 21 14H20M5 9V8C5 6.89543 4.10457 6 3 6C1.89543 6 1 6.89543 1 8V12C1 13.1046 1.89543 14 3 14H5M5 16V14M5 14H20M20 14V16'
        stroke='#0C88C6'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default CategoryMebel;
