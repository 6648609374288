import { styled } from "@mui/material/styles";




const StyledChildWidget = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;

  border-top: 1px solid #f1f1f1;
  width: 100%;
  background-color: #fff;
  padding: 1rem 0;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.06);

  .child-list {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }

  .child-item {
    padding: 10px 15px;
    a, span {
      color: ${props => props.theme.customTheme.colors.primary};
      text-decoration: none;
    }
  }

`;




const StyledCategoryWidget = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.125s ease;
  cursor: default;

  width: 100%;
  padding: 2rem 0;
  background-color: #fff;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.06);
  display: block;
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .main-list {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }

  .item {
    font-size: ${props => props.theme.customTheme.sizes.normal};
    line-height: 19px;
    font-weight: 500;

    cursor: pointer;
    border-radius: 25px;

    &:hover, &.active {
      text-decoration: underline;
      background-color: ${props => props.theme.customTheme.bg.lightPrimary};
    }
  }

  .main-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc((100% - 150px) / 6);

    img {
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    &:hover + ${StyledChildWidget} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export {
  StyledCategoryWidget,
  StyledChildWidget,
};
