import React, { useEffect, useState } from 'react';
import CatalogMenuWrapper from './style';
import Container from '@/Components/atoms/Container';
import { FaAngleRight } from 'react-icons/fa';
import CategoryElectronika from '@/Components/atoms/CommonSvg/categoryElectronika';
import CategoryFitnes from '@/Components/atoms/CommonSvg/categoryFitnes';
import CategoryTransport from '@/Components/atoms/CommonSvg/categoryTransport';
import CategoryModa from '@/Components/atoms/CommonSvg/categoryModa';
import CategoryMulk from '@/Components/atoms/CommonSvg/categoryMulk';
import CategoryBolalar from '@/Components/atoms/CommonSvg/categoryBolalar';
import CategoryMebel from '@/Components/atoms/CommonSvg/categoryMebel';
import CategoryBog from '@/Components/atoms/CommonSvg/categoryBog';
import CategoryService from '@/Components/atoms/CommonSvg/categoryService';
import CategoryHayvon from '@/Components/atoms/CommonSvg/categoryHayvon';
import { getNameKeyByLang, getSlugNameByLang } from '@/Utils/getNameKeyByLang';
import { useTranslation } from 'next-i18next';
import { pageLinks } from '@/Data/pageLinks';
import { useRouter } from 'next/router';
import { useGetCategories } from '@/ServerState/hooks/useGetCategories';

type Props = {
  closeCatalog: () => void;
};

const CatalogMenuNew = ({ closeCatalog }: Props) => {
  // @ts-ignore
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const currentLanguage = i18n.language as 'uz' | 'ru';
  const regionSlug = router.query.regionSlug as string;

  const getCategoryQuery = useGetCategories();

  const [activeId, setActiveId] = useState<number | null>(null);
  const [activeChildId, setActiveChildId] = useState<number | null>(null);

  const openCategoryPage = (slug: string) => {
    closeCatalog();
    router.push(pageLinks.categoryAddressPage({ addressSlug: regionSlug, categorySlug: slug }), undefined, {
      shallow: false,
    });
  };

  const categoryList = getCategoryQuery?.data?.data ?? [];
  const selectedCategory = categoryList.find(category => category.id === activeId) || categoryList[0];

  return (
    <CatalogMenuWrapper>
      <div className='wrapper' onClick={closeCatalog}>
        <div className='left'>
          <ul>
            {categoryList &&
              categoryList.map(category => (
                <li key={category.id}>
                  <button
                    className={`
                    ${activeId === category.id ? 'active' : ''}
                    `}
                    onMouseEnter={() => {
                      setActiveId(category.id);
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                      {category.id === 1 ? (
                        <CategoryElectronika />
                      ) : category.id === 2 ? (
                        <CategoryTransport />
                      ) : category.id === 3 ? (
                        <CategoryModa />
                      ) : category.id === 4 ? (
                        <CategoryMulk />
                      ) : category.id === 5 ? (
                        <CategoryBolalar />
                      ) : category.id === 6 ? (
                        <CategoryMebel />
                      ) : category.id === 7 ? (
                        <CategoryBog />
                      ) : category.id === 8 ? (
                        <CategoryFitnes />
                      ) : category.id === 9 ? (
                        <CategoryService />
                      ) : category.id === 10 ? (
                        <CategoryHayvon />
                      ) : (
                        ''
                      )}

                      {currentLanguage === 'uz' ? category.nameUz : category.nameRu}
                    </div>
                    {activeId === category.id ? <FaAngleRight /> : ''}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
      {activeId && (
        <div className='childs' >
          <h3 className='catalogCategoryMenu'>
            <span
              className='category-link'
              onClick={() => openCategoryPage(selectedCategory ? selectedCategory[getSlugNameByLang(currentLanguage)] : '#')}
            >
              {selectedCategory?.[getNameKeyByLang(currentLanguage)]}
            </span>
          </h3>
          <ul>
            {selectedCategory?.childs.map(childs => (
              <li key={childs.id}>
                <button
                  className={`
            ${activeChildId === childs.id ? 'active' : ''}
            `}
                  onMouseEnter={childs.childs.length ? () => setActiveChildId(childs.id) : () => {}}
                  // onMouseLeave={childs.childs.length ? () => setActiveChildId(null) : () => {}}
                  onClick={childs.childs.length ? () => {} : () => openCategoryPage(childs[getSlugNameByLang(currentLanguage)])}
                >
                  {childs[getNameKeyByLang(currentLanguage)]}
                  {childs.childs.length ? <FaAngleRight /> : ''}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedCategory?.childs.find(child => child.id === activeChildId)?.childs.length && (
        <div className='childs2'>
          <h3 className='catalogCategoryMenu'>
            <span
              className='category-link'
              onClick={() => {
                const child = selectedCategory?.childs?.find(child => child.id === activeChildId);
                if (child) {
                  const slug = child[getSlugNameByLang(currentLanguage)] || '#';
                  openCategoryPage(slug);
                }
              }}
            >
              {selectedCategory?.childs.find(child => child.id === activeChildId)?.[getNameKeyByLang(currentLanguage)]}
            </span>
          </h3>
          <ul>
            {selectedCategory?.childs
              .find(child => child.id === activeChildId)
              ?.childs.map(childs => (
                <li key={childs.id}>
                  <button onClick={() => openCategoryPage(childs[getSlugNameByLang(currentLanguage)])}>
                    {childs[getNameKeyByLang(currentLanguage)]}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      )}
    </CatalogMenuWrapper>
  );
};

export default CatalogMenuNew;
