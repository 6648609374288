import { styled } from '@mui/material/styles';
import { StyledCategoryWidget } from './CategoryWidget/widgetStyle';
import { TitledCardWrapper } from '@/Components/atoms/BaseComponents/BaseTitledCard/style';

const HeaderWrapper = styled('header')`
  background-color: #fff;
  color: #fff;
  z-index: 100;
  width: 100%;
  position: sticky;
  top: 0px;
  /* border-bottom: 1px solid ${props => props.theme.customTheme.colors.secondary}; */

  .site-main-header{
    /* box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.12); */
  }

  .header {
    /* position: sticky; */
    top: 0;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    transition: transform 0.3s ease;
    transform: translateY(-10px);
  }

  .header2 {
    transform: translateY(0px);
    transition: transform 0.5s ease-in-out;
    /* position: fixed; */
    background-color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
  }

  

  .header-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    .location {
      display: flex;
      align-items: center;
      cursor: pointer;
      p {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        user-select: none;
        margin: 0;

        span {
          font-weight: 700;
        }
      }
    }
  }

  .header__search-input {
    display: flex;
    align-items: center;
    gap: 30px;
    color: ${props => props.theme.customTheme.colors.dark};
    width: 100%;
    /* flex-grow: 0.6; */
    /* margin-right: auto; */
  }

  
  .add-button{
    width: 25%;
    border-radius: 12px;
    span{
      display: flex;
    align-items: center;
    gap: 10px;
    }
  }
  
  .katalog-button {
    cursor: pointer;
    /* margin-right: 30px; */
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${props => props.theme.customTheme.colors.primary};
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-family: Inter;

    svg{
      path{
       fill: ${props => props.theme.customTheme.colors.primary};
      }
    }
  }

  .katalog-button span {
    
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    /* width: 15%; */
    text-decoration: none;
    color: ${props => props.theme.customTheme.colors.primary};
    margin-right: 30px;
  }

  .logo-img {
  }

  .logo-text {
    font-family: Montserrat !important;
    /* font-size: ${props => props.theme.customTheme.sizes.middle}; */
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    letter-spacing: 3.88125px;
    display: flex;
    flex-direction: column;
    span {
      font-family: Inter !important;
      color: #000;
      text-align: center;
      font-size: 10px;
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .link-wr {
    padding-right: 15px;
  }
  .nav-link {
    margin-left: 30px;
    color: rgba(21, 21, 21, 1);
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    &:hover,
    &.active-navlink {
      color: rgba(12, 136, 198, 1);
      transition: color 0.3s ease;
      padding-bottom: 5px;
      border-bottom: 1px solid rgba(12, 136, 198, 1);
    }
  }

  .site-nav {
    &__list {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 16px;
      margin-left: auto;
    }

    

    &__item {
      /* height: 80px; */
      display: flex;
      gap: 10px;
      align-items: center;

      .bord{
      width: 1px;
      height: 20px;
      background-color: #E5E7E7;
      /* margin-left:-20px; */

    }

      .signIn-span {
        color: #0C88C6;
        font-size: 16px;
        font-weight: 500;
        margin-left: 8px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      height: 100%;
      text-decoration: none;
      padding: 12px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: ${props => props.theme.customTheme.colors.primary};
      transition: background-color 0.3s ease, color 0.3s ease;
      cursor: pointer;

      &:hover,
      &.active-navlink {
        /* background-color: #fff; */
        color: ${props => props.theme.customTheme.colors.primary};
      }
    }
    &__link--login {
      padding: 0; 
      img {
        margin-top: 5px;
        height: 35px;
        width: 35px;
        object-fit: cover;
        border-radius: 50%;
      }
      svg path {
        color: ${props => props.theme.customTheme.colors.snow};
        transition: color 0.3s ease;
        width: 27px;
        height: 24px;
      }
      &.active-navlink {
        padding: 0;
      }
    }

    &__link--like {
      border-radius: 50%;
      height: 44px;
      width: 44px;
      /* border: 1px solid ${props => props.theme.customTheme.colors.secondary}; */
      transition: border-color 0.3s ease;
      /* background-color: ${props => props.theme.customTheme.colors.primary}; */
      img {
        height: 44px;
        width: 44px;
        object-fit: cover;
        border-radius: 50%;
        transform: translate(-13px, 3px);
      }
      svg path {
        color: ${props => props.theme.customTheme.colors.snow};
        transition: color 0.3s ease;
        width: 27px;
        height: 24px;
      }
      &:hover {
        border-color: ${props => props.theme.customTheme.colors.primary};
      }
      &:hover,
      &.active-navlink {
        svg path {
          color: ${props => props.theme.customTheme.colors.primary};
        }
      }
    }
    &__link--like {
      svg path {
        /* color: ${props => props.theme.customTheme.colors.primary}; */
        stroke: ${props => props.theme.customTheme.colors.primary};
      }

      &:hover,
      &.active-navlink {
        svg path {
          color: #d20000;
          stroke: none;
        }
      }
    }

    &__link--create {
      height: auto;
      padding: 10px 33px;
      border-radius: 10px;
      color: #fff;
      border: 2px solid ${props => props.theme.customTheme.colors.danger};
      white-space: nowrap;
      background-color: #fff;
      color: ${props => props.theme.customTheme.colors.danger};
      &:hover {
        border: 2px solid ${props => props.theme.customTheme.colors.danger};
        color: #fff;
        background-color: ${props => props.theme.customTheme.colors.danger};
      }
      &.active-navlink {
        background-color: ${props => props.theme.customTheme.bg.danger};
        color: #fff;
      }
    }

    &__link--lang {
      height: 30px;
      /* padding: 0.5rem 1rem; */
      padding: 1.2rem 0.8rem;
      border-radius: 5px;
      background-color: #fff;
      color: ${props => props.theme.customTheme.colors.primary};
      margin-left: 10px;
      /* margin-right: 10px; */

      display: flex;
      gap: 5px;

      a {
        padding: 0 4px;
        text-decoration: none;
        color: ${props => props.theme.customTheme.colors.primary};
      }
      a.active {
        border-radius: 4px;
        background-color: ${props => props.theme.customTheme.bg.lightPrimary};
      }
      a:hover {
        text-decoration: underline;
      }
    }

    &__link--category:hover ${StyledCategoryWidget} {
      visibility: visible;
      opacity: 1;
    }
  }

  .drawer-open-btn {
    width: 40px;
    height: 40px;
    display: block;
    cursor: pointer;

    & > svg {
      color: ${props => props.theme.customTheme.colors.primary};
      width: 40px;
      height: 40px;
    }
  }

  .header-mobile-category {
    ${TitledCardWrapper} {
      box-shadow: none;
      border-radius: 0;

      li,
      button {
        padding: 8px 10px;
        margin-bottom: 10px;
      }
    }
  }

  .catalog-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    transition: width 300ms;
    overflow: hidden;
    z-index: 999;
    box-sizing: border-box;
  }

  .catalog-menu.open {
    height: 100vh;
    width: 1000px;
  }

  .menu {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 40px;
    left: 0;
    /* height: 0; */
    width: 0;
    transition: width 1s;
    overflow: hidden;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 16px;
        transition: 100ms;
        &:hover {
          font-weight: 600;
        }

        p {
          width: 100%;
          display: flex;
          gap: 20px;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .menu.open {
    height: auto;
    width: 20%;
    padding: 15px;
    padding-top: 0;
  }

  .menus {
    position: relative !important;
  }

  .right-menu {
    position: absolute !important;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media (max-width: 991.98px) {
    padding: 10px 0;

    .logo-wrapper {
      /* display: none; */
      img{
        width: 42px;
        height: 42px;
      }
      .logo-text{
          font-size: 18px;
          span{
            font-size: 8px;
          }
      }

    }

    .header__search-input {
      width: 100%;

      .katalog-button{
        display: none;
      }

      .site-nav__link--like{
        display: none;
      }

      .site-nav__link--create{
        font-size: 10px;
        line-height: 12px;
        padding: 10px;
        display: flex;
      }
    }

    .site-nav {
      &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: ${props => props.theme.customTheme.bg.darkPrimary};
      }
      &__list-catalog {
        display: none;
      }

     

      &__item {
        width: 100%;
        & > a {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    .header__search-input {
      /* display: none; */
    }
    .logo-wrapper {
      margin-right: auto;
    }
    .site-nav__link--lang {
      display: none;
      margin-right: 10px;
      a {
        padding: 0 2px;
      }
    }
  }
`;

export { HeaderWrapper };
