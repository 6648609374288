import React from 'react'
import { StyledMobileNavbar } from './style';
import Container from '@/Components/atoms/Container';
import { HiHome, HiPlus } from 'react-icons/hi';
import CommonSvg, { svgNames } from '@/Components/atoms/CommonSvg';
import { FaUserAlt } from 'react-icons/fa';
import { useTranslation } from 'next-i18next';
import Navlink from '@/Components/atoms/Navlink';
import { useContextSelector } from 'use-context-selector';
import { ModalContext } from '@/Context/Modal';
import BaselineMenu from '@/Components/atoms/CommonSvg/baselineMenu';

type Props = {}

const MobileNavbar = (props: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledMobileNavbar className='mobile-navbar'>
      <Container className='mobile-navbar__container'>
        <Navlink href={'/'} className='mobile-navbar__link'>
          <HiHome/>
          <div>{t('home')}</div>
        </Navlink>
        <Navlink href={'/catalog'} className='mobile-navbar__link cat'>
          <BaselineMenu/>
          <div>{t('katalog')}</div>
        </Navlink>
        <Navlink href={'/create'} className='mobile-navbar__link mobile-navbar__link--create'>
          <div className='mobile-navbar__svg-wrapper'>
            <HiPlus/>
          </div>
          <div>{t('add')}</div>
        </Navlink>
        <Navlink href={'/offers'} className='mobile-navbar__link'>
          <CommonSvg name={svgNames.Offer} />
          <div>{t('offers')}</div>
        </Navlink>
        <Navlink href={'/profile'} className='mobile-navbar__link'>
          <FaUserAlt/>
          <div>{t('profile')}</div>
        </Navlink>
      </Container>
    </StyledMobileNavbar>
  )
}

export default MobileNavbar;
