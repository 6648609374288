import React from 'react';

type Props = {};

const CategoryElectronika = (props: Props) => {
  return (
    <svg id='electronic' width='16' height='22' viewBox='0 0 16 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 17H8.01M8.5 17C8.5 17.2761 8.27614 17.5 8 17.5C7.72386 17.5 7.5 17.2761 7.5 17C7.5 16.7239 7.72386 16.5 8 16.5C8.27614 16.5 8.5 16.7239 8.5 17ZM6.3 21H9.7C11.3802 21 12.2202 21 12.862 20.673C13.4265 20.3854 13.8854 19.9265 14.173 19.362C14.5 18.7202 14.5 17.8802 14.5 16.2V5.8C14.5 4.11984 14.5 3.27976 14.173 2.63803C13.8854 2.07354 13.4265 1.6146 12.862 1.32698C12.2202 1 11.3802 1 9.7 1H6.3C4.61984 1 3.77976 1 3.13803 1.32698C2.57354 1.6146 2.1146 2.07354 1.82698 2.63803C1.5 3.27976 1.5 4.11984 1.5 5.8V16.2C1.5 17.8802 1.5 18.7202 1.82698 19.362C2.1146 19.9265 2.57354 20.3854 3.13803 20.673C3.77976 21 4.61984 21 6.3 21Z'
        stroke='#0C88C6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CategoryElectronika;
