import { IOption } from '@/Components/atoms/ReactSelect';
import { customStyles } from '@/Components/atoms/ReactSelect/styles';
import { colors } from '@/styles/colors';
import { sizes } from '@/styles/sizes';
import { GroupBase, StylesConfig } from 'react-select';
import { styled } from '@mui/material/styles';

const StyledSearchForm = styled('form')<{size: 'normal' | 'small'}>`
  width: 100%;
  position: relative;
  z-index: 5;

  .search-input {
    width: 100%;
    padding: 10px 14px;
    font-size: ${props => props.theme.customTheme.sizes.normal};
    background: #fff;
    border: 1.5px solid #0C88C6;
    border-radius: 12px;
    
    &::placeholder {
      font-weight: 400;
      color: ${props => props.theme.customTheme.colors.dark800};
    }
    &:focus {
      box-shadow: 0 0 1px 1px ${props => props.theme.customTheme.colors.dark500};
    }
  }

  .search-input-list:focus-within .suggestions {
    display: block;
  }
  &:focus-within .suggestions {
  }
  .suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;

    width: 100%;
    max-height: 200px;
    overflow: auto;
    background-color: #fff;
    color: #222;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 6px 6px;


    &__list {
      margin: 0;
      padding: 1rem 0.625rem;
      list-style: none;
    }

    &__item {
      width: 100%;
      border: 0;

      padding: 0.75rem 0.625rem;
      border-radius: 0.375rem;

      font-size: ${props => props.theme.customTheme.sizes.normal};
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      cursor: pointer;
      user-select: none !important;

      font-size: ${props => props.theme.customTheme.sizes.xSmall};
      outline: none;
      transition: background-color 0.1s ease, color 0.1s ease;
      caret-color: #fff !important;
      text-decoration: none;

      &:hover, &:active, &:focus {
        background-color: ${props => props.theme.customTheme.bg.primary};
        color: #fff;
        caret-color: ${props => props.theme.customTheme.bg.primary} !important;
      }
    }
  }

  .search-regions-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    /* width: 20rem; */
    width: 5.625rem;

    display: flex;
    gap: 1rem;
  }

  .search-regions {
    position: relative;
    top: ${props => props.size === 'normal' ? '10%' : '5%'};

    flex-grow: 1;
    height: 80%;
  }

  .search-btn {
    position: absolute;
    top: 50%;
    right: 0.625rem;
    min-width: 33px;
    transform: translateY(-50%);
    border-radius: 0.375rem;
    padding: 0;
   

    flex-shrink: 0;
    /* width: ${props => props.size === 'normal' ? '5.625rem' : '1rem'}; */
    outline: none;
    /* height: calc(100% - 0.75rem); */
    border: 0;
    /* padding: 0.375rem; */
    background: #fff;

    cursor: pointer;

    svg {
      color: #0C88C6;
      font-size: ${props => props.theme.customTheme.sizes.xMiddle};
      transition: color 0.3s ease;
      width: 18px;
      height: 18px;

      path {
        /* fill:#0C88C6 !important; */
      }
    }
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      box-shadow: none;
    }
  }


  // ----------------------------- MEDIA ----------------------------
  @media (max-width: 767.98px) {
    .search-regions-wrapper {
      width: 3rem;
    }
    .search-input {
      font-size: ${props => props.theme.customTheme.sizes.middle};
    }
    .search-regions {
      display: none;
    }
  }
`;

const AddressSelectStyles:StylesConfig<IOption, false, GroupBase<IOption>> = {
  ...customStyles,
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: '0.5rem',
    fontSize: sizes.small,
    lineHeight: '15px',
    fontWeight: 400,
    color: colors.darkerPrimary,
    padding: 0,

    border: 'none !important',
    boxShadow: 'none !important',
  }),
  indicatorSeparator: (baseStyles, state) => ({
    ...baseStyles,
    display: 'none'
  }),
};

export {
  StyledSearchForm,
  AddressSelectStyles,
};
