import { CDN_BASE_URL } from '@/Data/Constants';
import imageCompression, { Options } from 'browser-image-compression';
import ThumborUrlBuilder from 'thumbor-url-builder';

// ------------------------------ CONFIGS ------------------------------
const defaultOptions: Options = {
  maxSizeMB: 1,
};

// ------------------------------ FUNCTIONS ------------------------------
function buildUrlImage(path: string, width: number, height: number) {
  if (path) {
    const thumborURL = new ThumborUrlBuilder('Joyla+@sDk()', CDN_BASE_URL);
    return thumborURL.setImagePath(path).resize(width, height).smartCrop(true).buildUrl();
  }
  return path;
}

export function getImageUrlByPath(path: string, width: number = 50, height: number = 50) {
  return buildUrlImage(path, width, height);
}

export function getImageUrlByUrl(path: string) {
  if (!path) return path;
  const thumborURL = new ThumborUrlBuilder('Joyla+@sDk()', CDN_BASE_URL);
  return thumborURL.setImagePath(path).smartCrop().buildUrl();
}

export function compressImageFile(imageFile: File, options = defaultOptions) {
  return imageCompression(imageFile, options);
}
