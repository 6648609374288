import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const PlusSvg: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 8.00011H15M8 1V15' stroke='white' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};

export default PlusSvg;
