import { styled } from "@mui/material/styles";

const StyledSiteFooter = styled('footer')`
  padding: 1rem 0 3.125rem;
  background-color: #ffffff;

  .site-footer {
    &__container {
    }

    &__logo {
      display: flex;
      align-items: center;
      gap: 10px;
      /* margin-bottom: 1.5rem; */
      text-decoration: none;
      color: ${(props) => props.theme.customTheme.colors.primary};

      img {
        flex-shrink: 0;
        width: 52px;
        height: auto;
      }
      span {
        font-family: Montserrat;
        font-size: ${(props) => props.theme.customTheme.sizes.xLarge};
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 3.88125px;
      }
    }

    &__row {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between !important;
      width: 100%;
    }

    &__col {
      &:nth-child(1) {
        padding-top: 50px;
         width: 30%;
        /* background-color: red; */
      }
      &:nth-child(2) {
        padding-top: 50px;
         width: 22%;
        /* background-color: green; */
      }
      &:nth-child(3) {
        padding-top: 50px;
         width: 22%;
        /* background-color: yellow; */
      }
      &:nth-child(4) {
        padding-top: 50px;
         width: 20%;
        /* background-color: yellow; */
      }
    }

    &__col-title {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      color: rgba(157, 160, 169, 1);
      margin-top: 0;
      margin-bottom: 24px;
      text-transform: uppercase;
    }

    &__col-list {
      margin: 0;
      padding: 0;
      list-style: none;

      & > li {
        margin-bottom: 18px;
      }

      & > li a,
      & > li div {
        text-decoration: none;
        font-size: 16px;
        font-weight: 500px;
        line-height: 19px;
        color: ${(props) => props.theme.customTheme.colors.dark};
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__first-col-item {
      display: flex;
      width: 70%;
      justify-content: space-between;
      gap: 2rem;
      align-items: flex-start;

      p{
        font-weight: 500;
      }

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      & > *:first-child {
        flex-grow: 1;
      }
    }

    &__contact {
      font-size: ${(props) => props.theme.customTheme.sizes.normal};
      line-height: 19px;
      color: ${(props) => props.theme.customTheme.colors.dark};
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        font-weight: 500;
        font-size: 14px;
      }

      a {
        color: ${(props) => props.theme.customTheme.colors.dark};
        text-decoration: none;
        font-size: ${(props) => props.theme.customTheme.sizes.normal};
        font-weight: 500;
      }
      a:hover {
        text-decoration: underline;
      }
      & > * {
        margin-top: 0;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__contact-phones {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
      
      p{
        font-size: 16px;
        margin: 0;
        font-weight: 500;
        padding-bottom: 10px;
      }
    }

    &__download {
      display: flex;
      gap: 10px;
      justify-content: start;
      margin-top: 20px;

      img {
        display: block;
        max-width: 155px;
        height: auto;
      }
    }

    &__download-link {
      display: block;

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
  }

  @media (max-width: 991.98px) {
    .site-footer {
      &__login {
        width: 70%;
      }

      &__row {
        width: 100%;
        flex-direction: column;
        gap: 2rem;
      }
      &__col {
        width: 100% !important;
      }
      &__first-col-item {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 767.98px) {
    .site-footer {
      &__row{
        width: 100%;
        flex-wrap: wrap !important;

        .top{
          width: 100% !important;
          text-align: center;
          a{
            width: 100% !important;
            justify-content: center;
          }
          .site-footer__first-col-item{
            width: 100%;
          }
        }
      }

      &__col{
        width: 40% !important;
      }
    }
  }
`;

const StyledSiteFooterBottom = styled('div')`
  background-color: rgba(249, 250, 252, 1);
  color: black;
  font-family: "Inter", sans-serif;

  .inner {
    padding: 17px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-top: 1px solid #EEEEEE; */
  }

  span,
  a {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-decoration: none;
    color: rgba(123, 126, 134, 1);
  }
  .site-footer {
    &__first-col-item {
      display: flex;
      justify-content: space-between;
      gap: 2.5rem;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      & > *:first-child {
        flex-grow: 1;
      }
    }

    &__login {
      position: relative;
      width: 100%;
    }

    &__login-input {
      width: 100%;
      padding: 14px 150px 12px 20px;
      border-radius: 3px;
      border: 0;
    }

    &__login-btn {
      position: absolute;
      right: 3px;
      top: 50%;
      transform: translateY(-50%);

      /* padding: 10px; */
      height: calc(100% - 6px);
      width: 135px;
      white-space: nowrap;
      justify-content: center;
    }

    &__social-list {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      gap: 1rem;
    }

    &__social-item {
      // background-color: ${(props) => props.theme.customTheme.bg.darkPrimary};
      // border-radius: 50%;
      // border: 2px solid transparent;
      transition: background-color 0.3s ease, border-color 0.3s ease;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        color: rgba(12, 136, 198, 1);
        transition: color 0.3s ease;
        width: 24px;
        height: 24px;
      }

      &:hover {
        svg {
          color: ${(props) => props.theme.customTheme.colors.darkPrimary};
        }
        background-color: transparent;
        border-color: ${(props) => props.theme.customTheme.colors.darkPrimary};
      }
    }
  }

  @media (max-width: 991.98px) {
    padding-bottom: 1rem;
  }

  @media (max-width: 767.98px) {
    span,
    a {
      font-size: ${(props) => props.theme.customTheme.sizes.small};
    }
  }
`;

export { StyledSiteFooter, StyledSiteFooterBottom };
