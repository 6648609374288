import { IKeyValue, PageAble } from '@/Types/CommonTypes/index';
import { IFullProductItem, IProductItem } from '@/Models/Product/IProduct';
import apiService from '../ApiService';
import { QS } from '@/Services/QS';
import { IFilterState } from '@/Components/moleculas/FilterCard/useFilterData';
import { IAdvancedFilterParams } from '@/Models/Category';

export interface IGetProductParams extends Partial<IFilterState>, Partial<IAdvancedFilterParams> {
  page: number;
  size: number;

  user_lat?: number;
  user_lang?: number;

  name?: string;
  category_id?: number;
  created_by?: string;
  district?: number;
  orderNum?: number;

  premiumCount?: number;
}

interface similarPosts extends IKeyValue {
  category_id: number;
  user_lat: number;
  user_lang: number;
  page?: number; // 0
  size?: number; // 18
  more_like_this?: boolean; // true
}

interface IIncreaseBody {
  productId: number;
  userId: number;
}

async function getProductSuggestions(key: string) {
  return await apiService.setUrl(`/product-elastic/search`).setParams({ key }).request();
}

async function getProducts(params: IGetProductParams, token?: string, signal?: AbortSignal) {
  const alteredParams = QS.stringify(params, { addQueryPrefix: true, allowDots: true, skipNulls: true });

  return token
    ? await apiService
        .setUrl(`/products/v3${alteredParams}`)
        .setHeaders({ authorization: `Bearer ${token}` })
        .setSignal(signal)
        .request<PageAble<IProductItem>>()
    : await apiService.setUrl(`/products/v3${alteredParams}`).setSignal(signal).request<PageAble<IProductItem>>();
}

async function getSimilarProducts(params: similarPosts) {
  return await apiService.setUrl(`/products`).setParams(params).request<PageAble<IProductItem>>();
}

async function getProductDetail(id: number, token?: string) {
  return await apiService
    .setUrl(`/products/${id}`)
    .setHeaders(token ? { authorization: `Bearer ${token}` } : {})
    .request<IFullProductItem>();
}

async function increaseProductCallCount(body: IIncreaseBody) {
  return await apiService.setUrl(`/products/increase-phone-call-count`).setMethod('POST').setBody(body).request<IIncreaseBody>();
}

async function createProduct(body: object) {
  return await apiService.setUrl(`/products`).setMethod('POST').setBody(body).request();
}

async function editProduct(body: object) {
  return await apiService.setUrl(`/products`).setMethod('PUT').setBody(body).request();
}

async function getReasons(id: string | number) {
  return await apiService.setUrl(`/products/${id}/reasons`).setMethod('GET').request<IFullProductItem>();
}

async function getImagesThumbnail(id: string | number) {
  return await apiService.setUrl(`/products/getAllThumbnails?productId=${id}`).setMethod('GET').request();
}

async function getChatId(productId: string | number, token?: string) {
  return token
    ? await apiService
        .setUrl(`/products/TODO/productId=${productId}`)
        .setHeaders({ authorization: `Bearer ${token}` })
        .setMethod('GET')
        .request()
    : await apiService.setUrl(`/products/TODO/productId=${productId}`).setMethod('GET').request();
}

async function getCreateProductLimitnfo(token?: string) {
  return await apiService
    .setUrl(`/users/product-create-info`)
    .setHeaders(token ? { authorization: `Bearer ${token}` } : {})
    .request();
}

const ProductApi = {
  getProductSuggestions,
  getProducts,
  createProduct,
  editProduct,
  getReasons,
  getProductDetail,
  getSimilarProducts,
  increaseProductCallCount,
  getImagesThumbnail,
  getChatId,
  getCreateProductLimitnfo
};

export default ProductApi;
