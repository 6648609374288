import { GroupBase, StylesConfig } from "react-select";
import { IOption } from '.';
import { colors } from "@/styles/colors";
import { sizes } from "@/styles/sizes";



export const customStyles: StylesConfig<IOption, false, GroupBase<IOption>> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: '0.5rem',
    fontSize: '0.8125rem',
    fontWeight: 500,
    color: colors.secondary,
    padding: '0.2rem 0.5rem',

    border: `2px solid ${colors.primary}`,
    boxShadow: '0 0 1px 1px #fff, 0 0 1px 2px #fff',
    ...((state.isFocused) ? {
      border: `2px solid ${colors.primary}`,
      boxShadow: `0 0 1px 1px ${colors.primary}, 0 0 1px 2px rgba(255, 255, 255, 0.9)`
    } : {})
  }),

  container: (baseStyles, state) => ({
    ...baseStyles,
    border: 0,
  }),
  
  noOptionsMessage: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '0.8125rem',
  }),

  menuList: (base, state) => ({
    ...base,
    padding: 0,
    border: 0,
  }),

  menu: (baseStyles, state) => ({
    ...baseStyles,
    padding: 0,
    border: 0,
  }),

  option: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '0.8125rem',
    fontWeight: 500,
    ...(state.isSelected ? {
      backgroundColor: colors.primary,
      color: '#fff'
    } : {})
  }),

  indicatorSeparator: (baseStyles, state) => ({
    ...baseStyles
  }),

  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles
  }),

  input: (baseStyles, state) => ({
    ...baseStyles,

  })
}

export const withoutBorderStyles:StylesConfig<IOption, false, GroupBase<IOption>> = {
  ...customStyles,
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: '0.5rem',
    fontSize: sizes.normal,
    lineHeight: '19px',
    fontWeight: 400,
    color: colors.darkerPrimary,
    padding: 0,

    border: 'none !important',
    boxShadow: 'none !important',
  }),
  indicatorSeparator: (baseStyles, state) => ({
    ...baseStyles,
    display: 'none'
  }),
};