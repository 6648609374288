import React from 'react';

type Props = {};

const CategoryMulk = (props: Props) => {
  return (
    <svg id='mulk' width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6V4ZM9 6C9.55229 6 10 5.55228 10 5C10 4.44772 9.55229 4 9 4V6ZM5 8C4.44772 8 4 8.44772 4 9C4 9.55229 4.44772 10 5 10V8ZM9 10C9.55229 10 10 9.55229 10 9C10 8.44772 9.55229 8 9 8V10ZM5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14V12ZM9 14C9.55229 14 10 13.5523 10 13C10 12.4477 9.55229 12 9 12V14ZM5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18V16ZM9 18C9.55229 18 10 17.5523 10 17C10 16.4477 9.55229 16 9 16V18ZM19 21V22H20V21H19ZM1 21H0V22H1V21ZM5 6H9V4H5V6ZM5 10H9V8H5V10ZM5 14H9V12H5V14ZM5 18H9V16H5V18ZM13 10H15V8H13V10ZM18 13V21H20V13H18ZM19 20H13V22H19V20ZM15 10C16.6569 10 18 11.3431 18 13H20C20 10.2386 17.7614 8 15 8V10ZM5 2H9V0H5V2ZM12 5V21H14V5H12ZM13 20H1V22H13V20ZM2 21V5H0V21H2ZM9 2C10.6569 2 12 3.34315 12 5H14C14 2.23858 11.7614 0 9 0V2ZM5 0C2.23858 0 0 2.23858 0 5H2C2 3.34315 3.34315 2 5 2V0Z'
        fill='#0C88C6'
      />
    </svg>
  );
};

export default CategoryMulk;
