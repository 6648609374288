import React from 'react';

type Props = {};

const CategoryFitnes = (props: Props) => {
  return (
    <svg id='fitnes' width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 3C3 1.89543 3.89543 1 5 1V1C6.10457 1 7 1.89543 7 3V15C7 16.1046 6.10457 17 5 17V17C3.89543 17 3 16.1046 3 15V3Z'
        stroke='#0C88C6'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M19 3C19 1.89543 18.1046 1 17 1V1C15.8954 1 15 1.89543 15 3V15C15 16.1046 15.8954 17 17 17V17C18.1046 17 19 16.1046 19 15V3Z'
        stroke='#0C88C6'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M7 9C7 8.44772 7.44772 8 8 8H14C14.5523 8 15 8.44772 15 9V9C15 9.55228 14.5523 10 14 10H8C7.44772 10 7 9.55228 7 9V9Z'
        stroke='#0C88C6'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M1 9C1 8.44772 1.44772 8 2 8V8C2.55228 8 3 8.44772 3 9V9C3 9.55228 2.55228 10 2 10V10C1.44772 10 1 9.55228 1 9V9Z'
        stroke='#0C88C6'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M19 9C19 8.44772 19.4477 8 20 8V8C20.5523 8 21 8.44772 21 9V9C21 9.55228 20.5523 10 20 10V10C19.4477 10 19 9.55228 19 9V9Z'
        stroke='#0C88C6'
        strokeWidth='2'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CategoryFitnes;
