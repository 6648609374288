export enum linkType {
  ordinary = 'ordinary',
  favourite = 'favourite',
  sign = 'sign',
  create = 'create',
}

export interface INavLink {
  label: string;
  path: string;
  type: linkType;
  [key: string]: any;
}

export const links: Array<INavLink> = [
  // {
  //   label: 'add',
  //   path: '/create',
  //   type: linkType.create
  // },
  // {
  //   label: 'my_offers',
  //   path: '/offers',
  //   type: linkType.ordinary,
  // },
  // {
  //   label: 'favorites',
  //   path: '/favorites',
  //   type: linkType.favourite,
  // },
  {
    label: 'auth_sign',
    path: '/profile',
    type: linkType.sign,
  },
];
