import React from 'react';

type Props = {};

const CategoryService = (props: Props) => {
  return (
    <svg id='service' width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.08057 4.51646L5.51363 3.0831L2.03487 1.50152C1.75695 1.37517 1.42968 1.43447 1.21392 1.65027C0.998683 1.86555 0.939531 2.19149 1.06543 2.46852L2.64751 5.94981L4.08057 4.51646ZM4.08057 4.51646L9.53757 9.97458M15.4472 13.0187C16.8854 12.958 18.3075 12.3772 19.4075 11.2769C21.1048 9.57927 21.3575 6.81608 20.5798 4.7089L16.7117 8.57174L13.8615 5.72537L17.7295 1.86256C15.6228 1.08463 12.7258 1.19852 11.0285 2.89618C9.19734 4.72771 8.80534 7.4519 9.85519 9.6569L9.53757 9.97458M15.4472 13.0187C14.4913 13.0591 13.5284 12.8697 12.6482 12.4505L10.8975 14.2015M15.4472 13.0187L19.5496 17.1219C20.3402 17.9127 20.3402 19.1935 19.5496 19.9842C18.7573 20.7767 17.4713 20.7767 16.679 19.9842L10.8975 14.2015M10.8975 14.2015L5.07942 20.0208C4.30731 20.7931 3.05418 20.7931 2.28206 20.0208C1.51165 19.2502 1.51165 18.0022 2.28206 17.2316L9.53757 9.97458'
        stroke='#0C88C6'
        strokeWidth='2'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CategoryService;
