import useSWR, { SWRResponse } from 'swr';
import { QUERY_KEYS } from '../keys';
import CategoryApi from '@/Services/Axios/Controllers/CategoryApi';
import { IMinCategory } from '@/Models/Category';

interface GetCategoriesResponse {
  data: IMinCategory[];
  indexedSlugData: { [key: string]: IMinCategory };
}

export function useGetCategories(): SWRResponse<GetCategoriesResponse, any> {
  return useSWR(
    QUERY_KEYS.GET_CATEGORIES,
    async () => {
      const response = await CategoryApi.getAllCategories();
      return {
        data: response.data,
        indexedSlugData: Object.fromEntries(
          response.data.reduce((acc, item) => {
            acc.push([item.slug, item]);
            acc.push([item.slugRu, item]);

            item.childs.map(child => {
              acc.push([child.slug, child]);
              acc.push([child.slugRu, child]);
            });

            return acc;
          }, [] as [string, IMinCategory][])
        ),
      };
    },
    {
      fallbackData: {
        data: [],
        indexedSlugData: {},
      },
      revalidateOnMount: true,
    }
  );
}

// all/adresdan category tanlanganda
