export function getNameKeyByLang(lng: 'uz' | 'ru'): 'nameUz' | 'nameRu' {
  switch (lng) {
    case 'uz': {
      return 'nameUz';
    }
    case 'ru': {
      return 'nameRu';
    }
    default: {
      return 'nameUz';
    }
  }
}

export function getSlugNameByLang(lng: 'uz' | 'ru'): 'slug' | 'slugRu' {
  switch (lng) {
    case 'uz': {
      return 'slug';
    }
    case 'ru': {
      return 'slugRu';
    }
    default: {
      return 'slug';
    }
  }
}
