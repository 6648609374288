export const pageLinks = {
  home: '/',
  notFound: '/404',
  categoryPage: (slug: string) => '/category/' + slug,
  categoryAddressPage({ categorySlug = 'all', addressSlug }: { categorySlug?: string; addressSlug?: string }) {
    if (addressSlug) {
      return `/category/${categorySlug}/${addressSlug}`;
    } else {
      return '/category/' + categorySlug;
    }
  },
  addressPage(slug: string) {
    return '/category/' + slug;
  },
  productDetailPage(slug: string) {
    return '/item/' + slug;
  },
  home4: '/',
  home5: '/',
  item(slug: string | number) {
    return '/item/' + slug;
  },
  itemNotFound: '/item/not-found',
  checkAndOpenChat(productId: string | number) {
    return '/opening-chat/' + productId;
  },
  openChat(chatId: string | number) {
    return '/chat/' + chatId;
  },
  openNewChat(productId: string | number) {
    return '/new_chat/' + productId;
  },
  sellerDetail(id: number) {
    return `/user/${id}`
  }
};
